import React from "react"
import Seo from "../components/seo/Seo"

import Section from "../components/section/Section"
import SectionGrid from "../components/section/SectionGrid"
import SectionInfo from "../components/section/SectionInfo"
import SectionHeading from "../components/section/SectionHeading"
import SectionParagraph from "../components/section/SectionParagraph"
import Company from "../components/contact/Company"
import SectionInfoContent from "../components/section/SectionInfoContent"

const Imprint = () => {
  return (
    <>
      <Seo
        title={"Impressum | Avalum"}
        description={"Geschäftsführer, Handelsregister, USt-IdNr."}
      />
      <Section>
        <SectionGrid>
          <SectionInfo>
            <h1 className="font-medium text-xl mb-4 md:text-2xl lg:text-3xl">
              Impressum
            </h1>
            <Company />
            <SectionInfoContent>
              <SectionHeading>Geschäftsführer</SectionHeading>
              <SectionParagraph>Marko Vrca</SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>Handelsregister</SectionHeading>
              <SectionParagraph>
                Registernummer: HRB 127637
                <br />
                Handelsregister des Amtsgerichts Frankfurt am Main
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>USt-IdNr.</SectionHeading>
              <SectionParagraph>
                DE353496925
                <br />
                Finanzamt Frankfurt am Main
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>
                Genehmigung nach § 34 c GewO
                <br />
                Aufsichtsbehörde
              </SectionHeading>
              <SectionParagraph>
                Ordnungsamt der Stadt Frankfurt am Main, Kleyerstraße 86,
                D-60326 Frankfurt am Main
              </SectionParagraph>
            </SectionInfoContent>
          </SectionInfo>
        </SectionGrid>
      </Section>
    </>
  )
}

export default Imprint
