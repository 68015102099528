import React from "react"
import SectionHeading from "../section/SectionHeading"
import SectionInfoContent from "../section/SectionInfoContent"
import SectionParagraph from "../section/SectionParagraph"

const Company = () => {
  return (
    <SectionInfoContent>
      <SectionHeading>Avalum GmbH</SectionHeading>
      <SectionParagraph>
        Anschrift: Montgolfier-Allee 24, 60486 Frankfurt am Main
      </SectionParagraph>
    </SectionInfoContent>
  )
}

export default Company
